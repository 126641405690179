export default {
  data() {
    return {
      filters: {
        expiryDates: {
          fromDate: '',
          toDate: '',
        },
        customerStatus: {
          activePlan: false,
          expiredPlan: false,
          internetAccess: false,
          noInternetAccess: false,
          debt: false,
          noDebt: false,
          staticIp: false,
          pppoe: false,
          dynamicIp: false,
          hotspot: false,
          noInternetPlan: false,
          noPhoneNumber: false,
        },
      },
    }
  },
}
