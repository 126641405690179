<template>
  <div class="customer">
    <v-row>
      <v-col
        sm="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        class="text-md-right"
        sm="12"
        md="3"
      >
        <v-btn
          v-if="can('customer-create')"
          color="primary"
          outlined
          @click="showImportDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiImport }}
          </v-icon>
          Import
        </v-btn>
      </v-col>
      <v-col
        sm="12"
        md="3"
        class="text-md-right"
      >
        <v-btn
          v-if="can('customer-create')"
          color="primary"
          class="mb-5"
          @click="showAddUserDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Customer
        </v-btn>
      </v-col>
    </v-row>
    <customers-table
      :customer-url="'customers'"
      :show-add-user-dialog="showAddUserDialog"
      @show-edit-user-dialog="showAddUserDialog = true"
      @close-user-dialog="showAddUserDialog = false"
    />
    <v-divider />
  </div>
</template>
<script>
import {
  mdiPlus, mdiImport,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import internetConnectionTypes from '@/mixins/internetConnectionTypes'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import hasPermission from '@/mixins/hasPermission'
import PortalCredentialsMessageMixin from '@/mixins/portalCredentialsMessageMixin'
import customerFiltersMixin from '@/mixins/customerFiltersMixin'
import CustomersTable from '@/components/tables/CustomersTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    CustomersTable,
  },
  mixins: [billingIntervalName, internetConnectionTypes, internetConnectionTypeEnum, hasPermission, PortalCredentialsMessageMixin, customerFiltersMixin],
  data() {
    return {
      icons: {
        mdiPlus,
        mdiImport,
      },
      showAddUserDialog: false,
      internetConnectionType: this.$route.query.internet_connection_type,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Customers',
          disabled: true,
          to: { name: 'customers' },
        },
      ],
    }
  },
  mounted() {
    this.initializeBreadcrumbs()
    this.handleDebtParam()
  },
  methods: {
    initializeBreadcrumbs() {
      if (this.internetConnectionType === 'all') {
        this.breadcrumbs.push({
          text: 'All',
          disabled: true,
          to: { name: 'customers' },
        })
        this.showConfigurationDetails = true
      } else {
        this.updateBreadcrumbsForConnectionType()
      }
    },
    updateBreadcrumbsForConnectionType() {
      const internetConnectionType = parseInt(this.internetConnectionType, 10)

      this.breadcrumbs.push({
        text: this.getEnumKeyByValue(internetConnectionTypeEnum, internetConnectionType),
        disabled: true,
        to: { name: 'internet-plans', params: { internet_connection_type: internetConnectionType } },
      })
    },
    getEnumKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value)
    },
    handleDebtParam() {
      const debtParam = this.$route.query.debt
      if (debtParam) {
        this.filters.customerStatus.debt = debtParam === 'true'
        this.filterLabel = 'customers with debt'
        this.setSelectedStation('all')
        this.refreshStations(true)
      }
    },
  },
}
</script>

<style scoped>
.strikethrough {
  text-decoration: line-through;
  color: #9E9E9E; /* Gray color for the original price */
  margin-right: 10px;
}
</style>
